import React from 'react';
import { Helmet } from 'react-helmet';
import imageMap from '@/images/maps/src-map.json';

export default function Meta({
  title,
  description,
  canonical,
  og,
  fromWP,
  ogimage,
  ...ogProps
}) {
  // DEFAULTS
  const defaultImage = 'cxn-social';
  const domain = 'cxncollective.com';

  // HELPER FUNCS
  function getType(imageURL) {
    return imageURL.includes('.png') ? 'image/png' : 'image/jpeg';
  }
  function getImageStructure(domain, src, title) {
    return {
      title,
      url: `http://${domain}${src}`,
      secureUrl: `https://${domain}${src}`
    };
  }

  // LOGIC
  const finalImage = (() => {
    if (fromWP && ogimage !== undefined && Object.values(ogimage).length > 0) { // From WP
      const imageSplit = ogimage.title.split(' ');
      const joinImages =
        imageSplit.length > 1 &&
        imageSplit
          .join('-')
          .toLowerCase()
          .replace('.', '')
          .replace(',', '')
          .replace('(', '')
          .replace(')', '');
      const toLowerCaseImage =
        joinImages || String(ogimage.title).toLowerCase();
      const src = imageMap[toLowerCaseImage]?.originalImg || '';
      return getImageStructure(domain, src, toLowerCaseImage);
    }
    if (ogimage) { // Static
      return getImageStructure(domain, imageMap[ogimage]?.originalImg, ogimage);
    } // Default
    return getImageStructure(domain, imageMap[defaultImage]?.originalImg, defaultImage);
  })();
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {canonical && <link rel="canonical" href={canonical} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {canonical && <meta property="og:url" content={canonical} />}
      <meta property="og:image" content={finalImage.url} />
      <meta property="og:image:secure_url" content={finalImage.secureUrl} />
      <meta
        property="og:image:type"
        content={getType(finalImage.url)}
      />
      <meta
        property="og:image:width"
        content={fromWP && ogimage !== undefined && ogimage ? ogimage.width : '1200'}
      />
      <meta
        property="og:image:height"
        content={fromWP && ogimage !== undefined && ogimage ? ogimage.height : '1200'}
      />
      <meta
        property="og:image:alt"
        content={finalImage.title.replace(/-/g, ' ')}
      />
      <meta property="og:type" content="website" />
    </Helmet>
  );
}
